<template>
  <!-- Version: 1.1 -->
  <h2>1. Descripción del Juego</h2>
  <p>
    El juego Highlight Games IIHF World Championship es una simulación de software única de partidos de hockey sobre
    hielo seleccionados aleatoriamente para crear oportunidades de juego.
  </p>
  <h2>2. Descripción y Duración del Juego</h2>
  <p>
    Una sola ronda de un juego electrónico dura una media de 5 minutos, y consiste en los anuncios de los partidos, la
    visualización y los resultados.
  </p>
  <p>
    La selección de los momentos destacados de los partidos de hockey sobre hielo es estadísticamente independiente,
    aleatoria e impredecible, y generada por el Generador de Números Aleatorios - RNG.
  </p>
  <h2>3. Mercados de Apuestas</h2>
  <h3>3.1 Goles Exactos (Incluidas las Prórrogas)</h3>
  <p>
    El mercado de &quot;Goles Exactos&quot; significa que se marque un número exacto de goles durante un partido. Como
    tal, este tipo de apuesta significa que el apostante debe predecir correctamente el número exacto de goles marcados
    durante un partido, independientemente de cuál sea el resultado final concreto.
  </p>
  <h3>3.2 Goles Exactos del Equipo Local (Incluida la Prórroga)</h3>
  <p>Una apuesta sobre el número exacto de goles que marcará el equipo local en el partido.</p>
  <h3>3.3 Goles Exactos del Equipo Visitante (Incluida la Prórroga)</h3>
  <p>Una apuesta sobre el número exacto de goles que marcará el equipo visitante en el partido.</p>
  <h3>3.4 Ambos Equipos Marcarán (Tiempo Reglamentario)</h3>
  <p>
    Para ganar una apuesta AEM, ambos equipos deben marcar al menos un gol, por lo que cualquier resultado a partir de
    1-1 es una apuesta ganadora. La apuesta &quot;Ambos Equipos Marcarán&quot; ofrece a los apostantes la posibilidad de
    aprovechar este tipo de eventos para obtener cuotas rentables cuando sólo uno de los dos equipos ha conseguido
    marcar.
  </p>
  <h3>3.5 Resultado Correcto (Incluida la Prórroga)</h3>
  <p>
    Las apuestas de resultado correcto consisten en apostar sobre cuál cree que será el resultado final del partido de
    hockey sobre hielo. Con un máximo de 5 goles en un partido, hay 18 resultados posibles.
  </p>
  <h3>3.6 Resultado del Partido (1X2) (Incluida la Prórroga)</h3>
  <p>Apuesta a que el equipo local gana (1), el equipo visitante gana (2) o el partido termina en empate (X).</p>
  <h3>3.7 Resultado del Partido (1,2) (Incluida la Prórroga).</h3>
  <p>Apuesta a que gana el equipo local (1) y el visitante (2).</p>
  <h3>3.8 Resultado del Partido &amp; Más de/Menos de 3.5 Goles (Tiempo Reglamentario).</h3>
  <p>Apuesta a que el resultado del partido y el número total de goles será inferior o superior a 3,5 goles.</p>
  <h3>3.9 Resultado del Partido &amp; Más de/Menos de 3.5 Goles (Incluida la Prórroga).</h3>
  <p>Apuesta a que el resultado del partido y el número total de goles será inferior o superior a 3,5 goles.</p>
  <h3>3.10 Más de/Menos de 2.5 Goles del Equipo Local o Visitante (Incluida la Prórroga)</h3>
  <p>Apuesta a que el equipo local o el equipo visitante marcarán más o menos de 2,5 goles.</p>
  <h3>3.11 Más de 2.5, 3.5, 4.5 Goles (Incluida la Prórroga)</h3>
  <p>Apuesta a que el número total de goles marcados por ambos equipos será superior a 2,5, 3,5 ó 4,5 en el partido.</p>
  <h3>3.12 Hándicap 2-way (Tiempo Reglamentario)</h3>
  <p>
    Línea de apuestas con las cuotas establecidas para determinar el favorito o el no favorito en el partido. Este
    hándicap crea una línea de hándicap de &quot;medio gol&quot; entre los 2 equipos, donde hay 2 resultados posibles.
  </p>
  <h3>3.13 Hándicap 3-way (Tiempo Reglamentario)</h3>
  <p>
    Una línea de apuestas con las cuotas establecidas para determinar el favorito o el no favorito en el partido. Este
    hándicap crea una línea de hándicap de &quot;goles enteros&quot; entre los 2 equipos, donde hay 3 resultados
    posibles (es decir, incluyendo un empate).
  </p>
  <h3>3.14 Período Más Goleador (Tiempo Reglamentario)</h3>
  <p>
    Apuesta sobre el periodo en el que se marcarán más goles. Puede ser el Periodo 1, el Periodo 2, el Periodo 3 o un
    Empate.
  </p>
  <h3>3.15 Equipo Que Gana Más Periodos (Tiempo Reglamentario)</h3>
  <p>
    Una apuesta sobre qué equipo ganará más periodos.<br />Puede ser el equipo local, el equipo visitante o un empate.
  </p>
  <h3>3.16 ¿Qué Equipo Marcará Primero? (Incluida la Prórroga)</h3>
  <p>Una apuesta sobre qué equipo marcará primero en el partido</p>
</template>
